

var modifyFormURL = {
    init: function() {
        this.setupFormUrl();
    },
    setupFormUrl: function() {
        $("#rex-xform form").attr("action", $("#rex-xform form").attr('action')+"#hero");
    }
};

var getHelpers = {
    init: function() {
        this.relExternal();
        this.socialmedia();
        this.formHeight();
    },
    relExternal: function() {
        $(document).on("click", "a[rel=external]", function(e) {
            window.open($(this).attr("href"), "_blank");
            e.preventDefault();
        });
    },
    socialmedia: function() {
        $(".page__footer .footer__wrapper .footer__links").on("click", "a:nth-child(4)", function(e) {
            e.preventDefault();
            $(this).attr("rel", "");
            $(".flyout-menu").toggleClass("open");
        });


        $(".flyout-menu li a").on("click", function() {
            $(".flyout-menu").removeClass("open");
        });
    },
    formHeight: function() {
        var heightParentEl = $('#rex-xform .left__col').outerHeight();
        var heightElRight = $('#rex-xform .right__col:nth-child(3)').innerHeight();
        var newHeight = (heightParentEl-heightElRight);
        var newHeightSubMargin = (newHeight - 10);

        $('#rex-xform .placeholder__form').css('height',newHeightSubMargin);
    }
};



var getAccordion = {
    init: function() {
        this.setup();
    },
    setup: function() {
        $(".JSaccordion").accordion({
            collapsible: true,
            active: 'none',
            heightStyle: 'content'
        });
    }
};


var getMoreText = {
    init: function() {
        this.getMoreText();
    },
    getMoreText: function() {
        $('.form__legal-text').on('click', 'a', function(e) {
            e.preventDefault();
            $('.more__text').toggleClass('open');
        })
    }
};


$(document).ready(function () {
    $('#menu_trigger_button').on('click', function () {
        var elemId = $(this).attr('data-trigger');
        var triggerClass = $(this).attr('data-trigger-class');

        var elem = $(elemId);
        elem.toggleClass(triggerClass);
        $(this).toggleClass(triggerClass);
    });



    
});

$(document).ready(function() {
    getHelpers.init();
    getAccordion.init();
    getMoreText.init();
    modifyFormURL.init();

    $('.formsubmit input').each(function () {
        $(this).on('click', function (e) {
            var loader = document.createElement('div');
            loader.className = 'loader';
            loader.innerHTML = 'Loading...';
            $(this).parent().append(loader);
            $(this).hide();
        });  
    });
});
